@import "../../globalStyle/variable.scss";

.footer {
  &-top {
    display: flex;
    color: $text;
    align-items: center;
    background-color: $bg;
    padding: 16px 42px;
    height: auto;
    .footer__text {
      display: flex;
      flex: 1;
      color: white;

      .footer__text1 {
        flex: 1;
        font-size: 18px;
        font-weight: 600;
        display:flex;
        align-items: center;
        position: relative;

        i {
          font-size: 20px; 
          margin-right: 16px;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          height:40px;
          background-color: rgba($color: #000000, $alpha: 0.3);
          width: 1px;
          right: 10%;
          top: 0
        }
      }

      .footer__text2 {
        flex: 1;
        
      }
    }
    .footer__input {
      flex:1;
      text-align: center;

      input {
        border: none;
        outline: none;
        padding: 10px;
        margin: 0 10px 0 40px;
        width: 60%;
        border-radius: 5px;
      }
    
      button {
        padding: 8px 16px;
        background-color: $primary;
        color: white;
        border: none;
        border-radius: 5px;
        width: 20%;
      }  
    }
  }
  &-bottom {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: $spacing-lg;
    background-color: black;
   
    .heading {
      color: $primary;
      height: 45px;
      font-size: 22px;
    }

    ul {
      list-style-type: none;
      padding: 0;
     

      li {
        color: white;
        font-size: 15px;
        padding: 6px;
      }
    }

    .contact {
      grid-column: 4/6;
      color: white;

      .address {
        padding-top: 8px;
      }
      
      .phone {
        font-size: 24px;
      };
      .email {
        margin: 8px 0;
      }

    }
  }

} 
 


