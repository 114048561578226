@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400&display=swap');
$ubuntu-font: 'Work Sans', sans-serif;


$primary: #1da3a3;
$bg: #777777;
$bg-2: #777777;
$text: #111010;
$text2: #777777;
$text3: white;

$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

$sm: 600px;
$md: 900px;
$lg:1200px;
$xl:1800px;

@mixin  respond($breakpoint) {
  @if $breakpoint == sm{
      @media (max-width: $sm){@content}; //600px
  }
  @if $breakpoint == md{
      @media (min-width:$sm) and (max-width: $md){@content};  //900px
  }
  @if $breakpoint == lg{
    @media (min-width:$md) and (max-width: $xl){@content}; //1200px
}
  @if $breakpoint == xl{
      @media (min-width: $xl){@content}; //1800px
  }
}

@mixin button {
  background: rgba($color: #000000, $alpha: 0.9);
  padding: 6px 12px;
  border-radius: 5px;
  color: $text3;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin flexbox($justify: unset ,$align-item : unset ,$gap : 0) {
  display: flex;
  justify-content: $justify;
  align-items: $align-item;
  gap: $gap
}