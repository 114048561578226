@import "./variable.scss";
.app.responsive {
  @include respond(md) {
    select {
      color: black;
    }
    .nav {
      .subnav {
        display: none;
      }
      .main-nav {
        
        .mobile-menu__icon {
          display: block;
          padding: 4px;
          &:hover {
            color: $primary;
          }
        }
        .menu {
          display: none;
        }
        .mobile-menu{
          flex-direction: column;          
          position: fixed;
          width: 100vw;
          height: 100vh;
          background: black;
          padding: 64px 0;
          top: 0;
          left: 0;
          z-index: 999;
          color: white;
          margin: auto;
          text-align: center;
          display: block;

          .mobile-menu__close {
            display: block;
            color: white;
            position: absolute;
            right: 20px;
            padding: 4px;
            font-size: 30px;
            top: 20px;

            &:hover {
              background: $primary;
            }
          }

          li {
            margin: $spacing-md 0;
            padding: 12px 0;
            font-size: 24px;
            a {
              color: white;
              &:hover {
                color: $primary;

              }
            }
          }
        }

        .cart-search {
          a {
            padding: 0;
          }
          .user-options {
            &__avatar {
              span {
                display: none;
               }
            }
          }
          &__icon {
            display: none;
          }
          &__quantity {
            top: -10px;
            right: -8px;

          }
          
        }
      }

      .cart {
        width: 100%;
        
        &__items {
          padding: 16px 0;
        }

        &__item-total {
          height: auto;
        }

        &__item-button {
          a {
            height: auto;
          }
        }
      }
     }

     .slider {   
      &-desc {
        left:50%;
        transform: translate(-50%,-50%);
        font-size: 12px;
        button {
         width: 10px;
         height: 10px;
        }
      }
     }

    .widgets {
     
      .widget {
        
        &-desc {
          font-size: 15px;
          top: 50%;
          left: 50%;
          width: 250px;
          transform: translate(-50%, -50%);
          a {           

            padding: 2px 0;
          }
        }
      }
    }

    .products__container {
      
      .categories, .cards {
        grid-template-columns: repeat(2,1fr);
      }

      .products__heading {
        a {
          display: none;
        }
      }
      
    }

    .footer {
      &-top {
        flex-direction: column;
        font-size: 16px;
  
  
        .footer__text {
          flex-direction: column;
          text-align: center;
  
          &1 {
            flex-direction: column;
  
            i {
              font-size: 40px !important;
              margin-top: $spacing-md;
            }
  
            &::after {
              display: none !important;
            }
          }
  
          &2 {
            margin: $spacing-sm 0;
          }
        }
  
        .footer__input {
          width: 100%;
          display: flex;
          flex-direction: column;
  
          input {
            width: 100%;
            margin: 0;
          }
  
          button {
            margin: 0 auto;
            width: 40%;
            padding: 4px 0;
            margin-top: $spacing-md;
          }
        }
      }
  
      &-bottom {
        grid-template-columns: 1fr; 
  
  
        .contact {
          grid-column: auto;
          
          p {
            font-size: 16px;
          }

          .email {
            margin: $spacing-sm 0;
          }
          }
        
        ul {  
          margin-bottom: $spacing-md;
          li {
            padding:6px 0;
          }
        }
      }
     }

   .product__container {
    margin-top: 16px;
    padding: 0;
    display: block;

    .product__img {
      &-lg {
        height: 100%;
        width: 100%;
      }

      &-desc {
        font-size: 18px;
      }
    }

    .product__details {
      select {
        width: 50%;
      }
    }

    .product__wrapper {
      margin-top: $spacing-md;
      padding: 0;

      .product {
        width: 100%;

        &__price, &__detail, &__desc{
          margin: $spacing-md 0;
        }

        &__desc-title {
          margin-top: 24px;
        }
    
      }
    }
    }

    .login {

      .login__container {
        width: 80%;
      }

      &__input {
        height: 48px;

        input {
          height: 100%;
        }
      }

      .keep-login {
        margin: $spacing-sm 0;
      }

     &__button {
      margin: $spacing-sm 0;
     }

     &__register {
      margin: 18px 0;
     }

      &__others {
        gap: $spacing-md;
        margin: $spacing-md 0;
      }
      

    }

    .cart-page {
      display: block;
      padding: 32px 0;
      &__product {
        position: relative;
        display: block;

        &-input {
          .input-quantity__close {
            top: 10px;
            right: 10px;
            position: absolute;

          }
        }

        .clear-item {
          display: block;
          position: absolute;
          top: 50%;
          right: 10px;

          &:hover {
            opacity: 0.5;
          }
        }
        
      }
      .text-note {
        margin-bottom: $spacing-md;
      }

     
    }

    .list {
      .search__input {
        input::placeholder {
          color: transparent;
        }
      }
      
      &__heading {
        margin: 32px 0;
      }

      .sort-products {
        select {
          color: black;
        }
      }

      .list__container {
        flex-direction: column;
        
  
        .list__filter {
          padding: 16px ;
        }
       
        &__heading {
          margin: 32px 0;
        }

        .sort-products {
          select {
            color: black;
          }
        }
       
  
        .list__left {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 999;
          background-color: white;
          height: 100%; 
  
          .filter-type {
            padding: 16px;
          }
  
          .filter__mobile-button {
            display: flex;
            justify-content: space-evenly;
          }
        }
  
        .mobile__filter {
            display: block;
        }
  
        .list__right {
          grid-template-columns: repeat(2,1fr);
          gap: 16px;
        }
        
        .clear-filter2 {
          display: none;
        }
      }
    }
   
    .checkout {
      &-left {
        padding: 0;
        border: none;

        .checkout__form {
          .checkout__select {
            flex-direction: column;
            select {
              padding: 8px 4px;
              color: black;
            }
          }
        }

      }
      .cart-page__summary {
        display: none;
      }
      &-step2 {
        &__delivery {
          padding: 8px 4px;
          color: black;
        }
        &__btn {
          flex-direction: column;
          gap: 16px;
          a {
            font-size: 16px;
          }
          button {
            width: 100%;
            text-align: center;
            font-size: 16px;
          }
        }
      }
      &-step3 {
        font-size: 18px;
        &__btn {
         a {
          font-size: 15px;
         }}}
    }

    .not-found {
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    .res-hidden {
      display: none;
    }

  }
  
  
  @include respond(sm) {
    select {
      color: black;
    }
    .nav {
      .subnav {
        display: none;
      }
      .main-nav {
        
        .mobile-menu__icon {
          display: block;
          padding: 4px;
          &:hover {
            color: $primary;
          }
        }
        .menu {
          display: none;
        }
        .mobile-menu{
          flex-direction: column;          
          position: fixed;
          width: 100vw;
          height: 100vh;
          background: black;
          padding: 64px 0;
          top: 0;
          left: 0;
          z-index: 999;
          color: white;
          margin: auto;
          text-align: center;
          display: block;

          .mobile-menu__close {
            display: block;
            color: white;
            position: absolute;
            right: 20px;
            padding: 4px;
            font-size: 30px;
            top: 20px;

            &:hover {
              background: $primary;
            }
          }

          li {
            margin: $spacing-md 0;
            padding: 12px 0;
            font-size: 24px;
            a {
              color: white;
              &:hover {
                color: $primary;

              }
            }
          }
        }

        .cart-search {
          a {
            padding: 0;
          }
          .user-options {
            &__avatar {
              span {
                display: none;
               }
            }
          }
          &__icon {
            display: none;
          }
          &__quantity {
            top: -10px;
            right: -8px;

          }
          
        }
      }

      .cart {
        width: 100%;
        
        &__items {
          padding: 16px 0;
        }

        &__item-total {
          height: auto;
        }

        &__item-button {
          a {
            height: auto;
          }
        }
      }
     }

     .slider {   
      &-desc {
        left:50%;
        transform: translate(-50%,-50%);
        font-size: 12px;
        button {
         width: 10px;
         height: 10px;
        }
      }
     }

    .widgets {
     
      flex-direction: column;
      .widget {
        
        &-desc {
          font-size: 15px;
          top: 50%;
          left: 50%;
          width: 250px;
          transform: translate(-50%, -50%);
          a {           

            padding: 2px 0;
          }
        }
      }
    }

    .products__container {
      
      .categories, .cards {
        grid-template-columns: 1fr;
      }

      .products__heading {
        a {
          display: none;
        }
      }
      
    }

    .footer {
      &-top {
        flex-direction: column;
        font-size: 16px;
  
  
        .footer__text {
          flex-direction: column;
          text-align: center;
  
          &1 {
            flex-direction: column;
  
            i {
              font-size: 40px !important;
              margin-top: $spacing-md;
            }
  
            &::after {
              display: none !important;
            }
          }
  
          &2 {
            margin: $spacing-sm 0;
          }
        }
  
        .footer__input {
          width: 100%;
          display: flex;
          flex-direction: column;
  
          input {
            width: 100%;
            margin: 0;
          }
  
          button {
            margin: 0 auto;
            width: 40%;
            padding: 4px 0;
            margin-top: $spacing-md;
          }
        }
      }
  
      &-bottom {
        grid-template-columns: 1fr; 
  
  
        .contact {
          grid-column: auto;
          
          p {
            font-size: 16px;
          }

          .email {
            margin: $spacing-sm 0;
          }
          }
        
        ul {  
          margin-bottom: $spacing-md;
          li {
            padding:6px 0;
          }
        }
      }
     }

   .product__container {
    margin-top: 16px;
    padding: 0;
    display: block;

    .product__img {
      &-lg {
        height: 100%;
        width: 100%;
      }

      &-desc {
        font-size: 18px;
      }
    }

    .product__details {
      select {
        width: 50%;
      }
    }

    .product__wrapper {
      margin-top: $spacing-md;
      padding: 0;

      .product {
        width: 100%;

        &__price, &__detail, &__desc{
          margin: $spacing-md 0;
        }

        &__desc-title {
          margin-top: 24px;
        }
    
      }
    }
    }

    .login {

      .login__container {
        width: 80%;
      }

      &__input {
        height: 48px;

        input {
          height: 100%;
        }
      }

      .keep-login {
        margin: $spacing-sm 0;
      }

     &__button {
      margin: $spacing-sm 0;
     }

     &__register {
      margin: 18px 0;
     }

      &__others {
        gap: $spacing-md;
        margin: $spacing-md 0;
      }
      

    }

    .cart-page {
      display: block;
      padding: 32px 0;
      &__product {
        position: relative;
        display: block;

        &-input {
          .input-quantity__close {
            top: 10px;
            right: 10px;
            position: absolute;

          }
        }

        .clear-item {
          display: block;
          position: absolute;
          top: 50%;
          right: 10px;

          &:hover {
            opacity: 0.5;
          }
        }
        
      }
      .text-note {
        margin-bottom: $spacing-md;
      }

     
    }

    .list {
      .search__input {
        input::placeholder {
          color: transparent;
        }
      }
      
      &__heading {
        margin: 32px 0;
      }

      .sort-products {
        select {
          color: black;
        }
      }

      .list__container {
        flex-direction: column;
        
  
        .list__filter {
          padding: 16px ;
        }
       
        &__heading {
          margin: 32px 0;
        }

        .sort-products {
          select {
            color: black;
          }
        }
       
  
        .list__left {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 999;
          background-color: white;
          height: 100%; 
  
          .filter-type {
            padding: 16px;
          }
  
          .filter__mobile-button {
            display: flex;
            justify-content: space-evenly;
          }
        }
  
        .mobile__filter {
            display: block;
        }
  
        .list__right {
          grid-template-columns: 1fr;
          gap: 16px;
        }
        
        
      }
    }
   
    .checkout {
      &-left {
        padding: 0;
        border: none;

        .checkout__form {
          .checkout__select {
            flex-direction: column;
            select {
              padding: 8px 4px;
              color: black;
            }
          }
        }

      }
      .cart-page__summary {
        display: none;
      }
      &-step2 {
        &__delivery {
          padding: 8px 4px;
          color: black;
        }
        &__btn {
          flex-direction: column;
          gap: 16px;
          a {
            font-size: 16px;
          }
          button {
            width: 100%;
            text-align: center;
            font-size: 16px;
          }
        }
      }
      &-step3 {
        font-size: 18px;
        &__btn {
         a {
          font-size: 15px;
         }}}
    }

    .not-found {
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    .res-hidden {
      display: none;
    }

  }
}


