.loader-container {
  height:100vh;
  width:100vw;
  margin: auto;
  position: fixed;
  z-index: 10;
  display:flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid black;
  border-bottom: 16px solid black;
  width: 150px;
  height: 150px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
